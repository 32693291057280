import React, { Component } from "react";
import FotoAcirfa from "../assets/fotoacirfarural.jpeg";
import styled from "styled-components";
import Cookies from "js-cookie";
import Login from "../components/Login";

// la caja con la imagen de fondo cubre el menú y el footer.

const CajaConTodo = styled.div`
background: url(${FotoAcirfa}) no-repeat;

background-attachment: scroll;
background-clip: border-box;
background-position: center;
background-size: cover;
----;
position: fixed;
top: -1px;
height: 100%;
width: 100%;
flex-flow: column;
overflow: auto;
flex-basis: 100%;
flex-grow: 1;
`;

class FotoAcirfaRural extends Component {
  state = {
    accessToken: Cookies.get("accessToken"),
    isLoggedIn: false,
    keyword: "",
  };

  // Esto es del Login
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.keyword === "acirfa") {
      Cookies.set("accessToken", "caca", { expires: 30 });
      this.setState({ isLoggedIn: true });
    }
  };

  componentDidMount() {
    // Comprueba el token en las cookies
    const token = this.state.accessToken;
    if (!token) {
      this.setState({ isLoggedIn: false });
    } else {
      if (token === "caca") {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ accessToken: null, isLoggedIn: false });
      }
    }

    let myHeaders = new Headers({
      route: "FotoAcirfaRural",
    });
    let myInit = {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      cache: "default",
    };
    fetch(`${process.env.REACT_APP_API_URL}/visit`, myInit).then(function (response) {
      return response.json();
    });
  }

  render() {
    const Contenido = (
      <>
        {" "}
        <CajaConTodo></CajaConTodo>;
      </>
    );
    return (
      <CajaConTodo>
        {this.state.isLoggedIn ? (
          Contenido
        ) : (
          <Login onChange={this.onChange} onSubmit={this.onSubmit} />
        )}
      </CajaConTodo>
    );
  }
}

export default FotoAcirfaRural;
