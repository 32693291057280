import React, { Component } from "react";
import imagenpinguino from "../assets/Reyes2021/Penguin.png";
import styled from "styled-components";

const CajaConTodo = styled.div`
  background-color: #00000;
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const ImagenPinguino = styled.img`
  max-height: 20%;
  max-width: 100%;
  margin: auto 0 0 auto;
`;

class Inicio extends Component {
  render() {
    return (
      <CajaConTodo>
        <ImagenPinguino src={imagenpinguino} />
      </CajaConTodo>
    );
  }
}

export default Inicio;
