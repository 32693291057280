import React, { Component } from "react";
import { Form, Grid } from "semantic-ui-react";

class Login extends Component {
  render() {
    return (
      <Grid colums="equal" centered style={{ margin: "auto" }}>
        <Form onSubmit={this.props.onSubmit}>
          <Form.Input
            label="Palabra Clave"
            name="keyword"
            placeholder=""
            onChange={this.props.onChange}
          />
          <Form.Button primary content="Magic" />
        </Form>
      </Grid>
    );
  }
}

export default Login;
