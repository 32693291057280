export const RandomWordsList = [
  "- fren-",
  "somato, som",
  "Fisio",
  "Andro",
  "Gineco, gin",
  "Ped",
  "Ger",
  "Copro",
  "Sialo",
  "orex",
  "Dips",
  "Narco",
  "mnes",
  "Crin",
  "Cefalo",
  "Cervico",
  "toraco",
  "laparo",
  "Osteo",
  "condro",
  "espondilo",
  "Mio",
  "neumo",
  "Histero",
  "orquio",
  "blefaro",
  "querato",
  "onico",
  "cardio",
  "gastro",
  "hepato",
  "a(n)-",
  "anti-",
  "contra-",
  "des-, dis-",
  "in-",
  "co-",
  "meta-",
  "para-",
  "yuxta-",
  "en-",
  "endo-",
  "intr(a)-",
  "hipo-",
  "sub-",
  "exo-",
  "extra-",
  "ab-, abs-",
  "ad-",
  "ante-, antero-",
  "dis-",
  "hiper-",
  "infra-",
  "peri-",
  "post-",
  "pre-",
  "pro-",
  "retro-",
  "supra-",
  "trans-",
  "ultra-",
  "espleno",
  "Dermo, dermato",
  "pleuro",
  "duodeno",
  "adeno",
  "esplacno",
  "procto",
  "odonto",
  "gingivo",
  "semio",
  "fob",
  "flog",
  "pio",
  "rrea",
  "cele",
  "hidro",
  "creno",
  "capno",
  "sidero",
  "argento o argiro",
  "saturno",
  "tomo",
  "ectom",
  "rraf",
  "pex",
  "plast",
  "rrexis",
  "lis, lisis",
  "poye",
  "leuco",
  "melano",
  "eritro",
  "porfiro",
  "flav",
  "ictero",
  "ciano",
  "poli",
  "oligo",
  "pleo",
  "pen",
  "pan",
  "micro",
  "macro",
  "esteno",
  "braqui",
  "dólico",
  "iso",
  "auto",
  "homo",
  "hetero",
  "iatro",
  "pato, patía",
  "polaqui",
  "esclero",
  "esquizo",
  "taqui",
  "bradi",
  "crio",
  "termo",
  "foto",
  "crono",
];
