import React, { Component } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Login from "../components/Login";
import GetAge from "../components/GetAgeES";
import { DateES } from "../components/DateES";

const PenguinBirthDate = "December 17, 1999";

// colores áfrica
// f5b6ed  f8b9e6  c8d3f3   cf8a8d  d298a4

const CajaConTodo = styled.div`
  background-color: #cf8a8d;
  color: white;

  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const CajaConTexto = styled.div`
  text-align: center;
  margin: auto;
`;

class Daily extends Component {
  state = {
    accessToken: Cookies.get("accessToken"),
    isLoggedIn: false,
    keyword: "",
  };

  // Esto es del Login
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.keyword === "juan") {
      Cookies.set("accessToken", "caca", { expires: 30 });
      this.setState({ isLoggedIn: true });
    }
  };

  componentDidMount() {
    // Comprueba el token en las cookies
    const token = this.state.accessToken;
    if (!token) {
      this.setState({ isLoggedIn: false });
    } else {
      if (token === "caca") {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ accessToken: null, isLoggedIn: false });
      }
    }
  }

  render() {
    const Contenido = (
      <>
        <CajaConTexto>
          <h1 className="Text">¡¡ Feliz {DateES} !!</h1>
          <h1 className="Text">Hoy cumplo {GetAge(PenguinBirthDate)}.</h1>
        </CajaConTexto>

        <CajaConTexto></CajaConTexto>
      </>
    );

    return (
      <CajaConTodo>
        {this.state.isLoggedIn ? (
          Contenido
        ) : (
          <Login onChange={this.onChange} onSubmit={this.onSubmit} />
        )}
      </CajaConTodo>
    );
  }
}

export default Daily;

// <h1> Today you are {GetAge(PenguinBirthDate)}</h1>
