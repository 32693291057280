import React, { Component } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Login from "../components/Login";
import { Grid, Segment, GridColumn } from "semantic-ui-react";

// colores áfrica
// f5b6ed  f8b9e6  c8d3f3   cf8a8d  d298a4

const CajaConTodo = styled.div`
  background-color: #cf8a8d;
  color: black;

  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const CajaConTexto = styled.div`
  text-align: center;
  margin: auto;
`;

class Bitcoin extends Component {
  state = {
    accessToken: Cookies.get("accessToken"),
    isLoggedIn: false,
    keyword: "",
    BTC: {},
    myMoney: [
      {
        date: "Juan",
        money: 100,
        btcprice: 28000,
        btc: 0.00359,
      },
      {
        date: "CoinBase",
        money: 0,
        btcprice: 0,
        btc: 0.00092325,
      },
    ],
  };

  // Esto es del Login
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.keyword === "acirfa") {
      Cookies.set("accessToken", "caca", { expires: 30 });
      this.setState({ isLoggedIn: true });
    }
  };

  componentDidMount() {
    // Comprueba el token en las cookies
    const token = this.state.accessToken;
    if (!token) {
      this.setState({ isLoggedIn: false });
    } else {
      if (token === "caca") {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ accessToken: null, isLoggedIn: false });
      }
    }
    fetch(`https://blockchain.info/ticker`)
      .then(function (response) {
        return response.json();
      })
      .then((response) =>
        this.setState({
          BTC: response.EUR,
        })
      );
  }

  render() {
    const { BTC, myMoney } = this.state;

    const ValoresApi = (
      <Segment color="teal">
        <h1>BTC: {BTC.last}€</h1>
      </Segment>
    );

    const FundsMap = myMoney.map((myMoney) => (
      <GridColumn key={myMoney.date} computer="5" tablet="8" mobile="16">
        <Segment color="teal">
          <h1>{myMoney.date}</h1>
          <h2>Inicial: {myMoney.money}€</h2>
          <h2>Actual: {Number((BTC.last * myMoney.btc).toFixed(2))}€</h2>
          <h2>
            Beneficio:
            {Number((BTC.last * myMoney.btc - myMoney.money).toFixed(2))}€
          </h2>
        </Segment>
      </GridColumn>
    ));

    /*
              <h2>
            Porcentaje:{" "}
            {Number(
              (
                (Number((BTC.last * myMoney.btc - myMoney.money).toFixed(2)) /
                  myMoney.money) *
                100
              ).toFixed(2)
            )}
            %
          </h2>
    */

    const Contenido = (
      <>
        <CajaConTexto>
          <Grid container centered divided="vertically">
            <Grid.Row>{ValoresApi}</Grid.Row>
            {FundsMap}
          </Grid>
        </CajaConTexto>
      </>
    );

    return (
      <CajaConTodo>
        {this.state.isLoggedIn ? (
          Contenido
        ) : (
          <Login onChange={this.onChange} onSubmit={this.onSubmit} />
        )}
      </CajaConTodo>
    );
  }
}

export default Bitcoin;
