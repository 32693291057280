var dateEvent = new Date(Date.now());
var options = {
  // weekday: "long",
  // year: "numeric",
  month: "long",
  day: "numeric",
};
export const DateES = dateEvent.toLocaleDateString("es-ES", options);

var options2 = {
  // weekday: "long",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
export const DateNum = dateEvent.toLocaleDateString("es-ES", options2);
