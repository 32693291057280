import React, { Component } from "react";
import styled from "styled-components";
import { RandomWordsList } from "../data/RandomWordsList";

const AppBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const CajaConTodo = styled.div`
background-color: #187bcd;
  ----;
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const CajaCentrada = styled.div`
  margin: auto;
  text-align: center;
`;

const Circle = styled.div`
  background-color: white;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  display: flex;
  z-index: 1;
`;

const Button = styled.button`
  background: #3c89d0;
  border-radius: 20px;
  border: 2px solid #3c89d0;
  color: white;
  padding: 0.5em 0.6em;
  font-size: 40px;
  margin: auto;
`;

const Texto = styled.h1`
  text-transform: uppercase;
  color: white;
  font-size: 45px;
  margin: auto;
  z-index: 2;
`;

// ***************************************** THIS FUNCTION RETURNS THE ARRAY IN A RANDOM NEW ORDER
function Randomise(a, b) {
  return 0.5 - Math.random();
}
const WordsInRandomOrder = RandomWordsList.sort(Randomise);
//console.log("Random", WordsInRandomOrder);

// ***************************************** THE MAIN COMPONENT
class RandomWord extends Component {
  state = {
    Words: WordsInRandomOrder,
    StartingCountdown: false,
    Start: false,
    WordDisplaying: false,
    WordIndex: 0,
    WordsFinished: false,
    CircleTransition: false,
  };

  // Ahora sólo empieza a mostrar contenido. Meter aquí más adelante el StartingCountdown con una cuenta atrás graciosa :)
  handleStartButton = () => {
    this.setState({ Start: true, WordDisplaying: true });
  };

  handleNextWord = () => {
    const { Words, WordIndex } = this.state;

    // SI ES LA ÚLTIMA PALABRA
    const Total = Words.length - 1;
    if (WordIndex === Total) {
      this.setState({
        WordsFinished: true,
        //CircleTransition: true,
      });
      //setTimeout(function () {
      //  this.setState({ CircleTransition: false });
      //}, 750);
    }
    // SI NO ES LA ÚLTIMA PALABRA :)
    else {
      this.setState({ WordIndex: WordIndex + 1 });
    }
  };

  render() {
    const { Start, WordDisplaying, WordIndex, Words, WordsFinished } = this.state;

    const StartButton = (
      <>
        <Circle style={{ zIndex: 0 }}>
          <Button className="Text" onClick={this.handleStartButton}>
            START
          </Button>
        </Circle>
      </>
    );

    const Contenido = (
      <>
        <Texto className="Text">
          {WordsFinished ? "Has acabado las palabras!! 🥳" : Words[WordIndex]}
        </Texto>
      </>
    );

    return (
      <>
        <AppBlock
          onClick={this.handleNextWord}
          style={
            WordDisplaying
              ? { zIndex: 9, position: "absolute" }
              : { zIndex: 0, position: "relative" }
          }
        />
        <CajaConTodo>
          <CajaCentrada>{Start ? Contenido : StartButton}</CajaCentrada>
        </CajaConTodo>
      </>
    );
  }
}

export default RandomWord;

/*
        <Circle className="CircleAnimation">


*/
