import React, { Component } from "react";
import "./css/App.css";
import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import Home from "./routes/Home";
import FotoAcirfaRural from "./routes/FotoAcirfaRural";
// import Reyes2021 from "./routes/Reyes2021";
import Love from "./routes/Love";
import Me from "./routes/Me";
import Bitcoin from "./routes/Bitcoin";
import RandomWord from "./routes/RandomWord";

// yarn remove qrcode.react IMPORTANT***************************************************************************************************************

function Visit() {
  let myHeaders = new Headers({
    route: `JUAN-${location.pathname}`,
  });
  let myInit = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
    cache: "default",
  };
  fetch(`${process.env.REACT_APP_API_URL}/visit`, myInit).then(function (response) {
    return response.json();
  });
  console.log(location.pathname);
  return null;
}

class App extends Component {
  state = {
    route: `PINGUINO${window.location.pathname}`,
  };

  componentDidMount() {
    let myHeaders = new Headers({
      route: this.state.route,
    });
    let myInit = {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      cache: "default",
    };
    fetch(`${process.env.REACT_APP_API_URL}/visit`, myInit);

    return null;
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/fotoacirfarural" component={FotoAcirfaRural} />

            <Route path="/randomword" component={RandomWord} />
            {/* <Route path="/reyes2021" component={Reyes2021} /> */}
            <Route path="/bitcoin" component={Bitcoin} />
            <Route path="/love" component={Love} />
            <Route path="/me" component={Me} />

            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;

/*
const CajaConTodo = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;
*/
