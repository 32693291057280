import React, { Component } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Login from "../components/Login";
import { GetLove, GetLoveEnd } from "../components/GetLoveES";
import { DateES } from "../components/DateES";

const Anniversary = "July 8, 2020";
const End = "February 13, 2022";

// Plascon Valentino Rose R2-A1-2 / #e0445c

const CajaConTodo = styled.div`
  background-color: #e0445c;
  color: white;

  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const CajaConTexto = styled.div`
  text-align: center;
  margin: auto;
`;

class Love extends Component {
  state = {
    accessToken: Cookies.get("accessToken"),
    isLoggedIn: false,
    keyword: "",
  };

  // Esto es del Login
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.keyword === "acirfa") {
      Cookies.set("accessToken", "caca", { expires: 30 });
      this.setState({ isLoggedIn: true });
    }
  };

  componentDidMount() {
    // Comprueba el token en las cookies
    const token = this.state.accessToken;
    if (!token) {
      this.setState({ isLoggedIn: false });
    } else {
      if (token === "caca") {
        this.setState({ isLoggedIn: true });
      } else {
        this.setState({ accessToken: null, isLoggedIn: false });
      }
    }
  }

  render() {
    const ContenidoSanValentin = (
      <>
        <h1 className="Text">Y hoy es San Valentín!!! ♥️</h1>
      </>
    );
    const SanValentin = DateES == "14 de febrero" ? ContenidoSanValentin : "";

    const Contenido = (
      <>
        <CajaConTexto>
          {/* <h1 className="Text">¡Llevamos {GetLove(Anniversary)} juntos!</h1> */}
          <h1 className="Text"> {GetLoveEnd(Anniversary, End)}</h1>
          <h1 className="Text"> we've been together 585 days</h1>
        </CajaConTexto>

        <CajaConTexto>{SanValentin}</CajaConTexto>
      </>
    );

    return (
      <CajaConTodo>
        {this.state.isLoggedIn ? (
          Contenido
        ) : (
          <Login onChange={this.onChange} onSubmit={this.onSubmit} />
        )}
      </CajaConTodo>
    );
  }
}

export default Love;

// <h1> Today you are {GetAge(AcirfaBirthDate)}</h1>
