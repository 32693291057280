class DateInterval {
  constructor(start, end) {
    // two Date instances
    if (start > end) [start, end] = [end, start]; // swap
    this.días = end.getDate() - start.getDate();
    this.meses = end.getMonth() - start.getMonth();
    this.años = end.getFullYear() - start.getFullYear();
    if (this.días < 0) {
      // Add the number of días that are left in the month of the start date
      this.días += new Date(
        start.getFullYear(),
        start.getMonth() + 1,
        0
      ).getDate();
      this.meses--;
    }
    if (this.meses < 0) {
      this.meses += 12;
      this.años--;
    }
  }

  toString() {
    const age = ["años", "meses", "días"]
      .map(
        (p) =>
          this[p] && this[p] + " " + p.slice(0, this[p] > 1 ? undefined : -1)
      )
      .filter(Boolean);
    //console.log("age", age);

    if (!age.length) return "0 días";

    // Cambia el "años, meses, días" por "años, meses and días"
    const last = age.pop();
    return age.length ? [age.join(", "), last].join(" y ") : last;
  }
}

function GetAge(dateString) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let dob = new Date(dateString);
  dob.setHours(0, 0, 0, 0);
  return new DateInterval(dob, today) + "";
}

export default GetAge;

// USE THIS:
//<h1> Today you are {GetAge("October 25, 2000")}</h1>

/*
// Demo: increment the date of birth by 1 at each test:
let d = new Date(2000, 1, 1);
setInterval(() => {
  const s = d.toJSON().slice(0, 10);
  console.log("Someone born on " + s + " is now " + GetAge2(s));
  d = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
}, 300);
*/
